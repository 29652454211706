<template>
  <div :class="classes" v-if="loadCont(data, type).flag">
    <div v-for="garbage of loadCont(data, type).icons" :key="garbage.id">
      <div class="mr-2 img other-icon" v-if="garbage.visible" :data-title="garbage.title">
        <img :src="garbage.icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { loadGarbageIcons } from '@/helpers/utils';
export default {
  name: 'GarbageIcon',
  props: {
    data: {
      type: Object
    },
    type: {
      type: Number
    },
    classes: {
      type: String
    }
  },
  methods: {
    loadCont(garbage, type) {
      return loadGarbageIcons(garbage, type);
    }
  }
};
</script>

<style scoped lang="scss">
.other-icon {
  position: relative;
}

.other-icon:hover::after {
  position: absolute;
  content: attr(data-title); /* Выводим текст */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  background: #ffffff;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153e;
  padding: 4px 8px;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(100% + 10px);
  left: -7px;
}

.other-icon:hover::before {
  content: '';
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 0L0 6H12L6 0Z' fill='white'/%3e%3c/svg%3e ");
  background-repeat: no-repeat;
  background-position: top left;
  width: 12px;
  height: 6px;
  position: absolute;
  z-index: 2;
  left: 7px;
  top: calc(100% + 4px);
}
</style>
