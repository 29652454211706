<template>
  <div>
    <h2
      v-if="title"
      class="taleggio"
    >
      {{ title }}
    </h2>
    <div class="mt-4 flex">
      <div
        class="star-container"
        @mouseenter.self.prevent="isHover = true"
        @mouseleave.self.prevent="isHover = false"
      >
        <span
          class="pointer"
          v-for="index in 5"
          :key="index"
          @mouseenter="selectedRating = index"
          @click="rateObject(index)"
        >
          <r-icon
            :icon="getIcon(index)"
            size="32"
            :fill="isUserRate ? 'rocky' : isHover ? '#4C80E6' : 'rocky'"
          />
        </span>
      </div>
    </div>
    <span class="mt-2 mozzarella text-rocky"> {{ subTitle }} </span>
  </div>
</template>

<script>
import Api from '@/api/PlaceApi';

export default {
  name: 'Rating',
  props: {
    title: {
      type: String,
      default: ''
    },
    val: {
      type: [Number, String, null],
      default: 2.4
    },
    avgRating: {
      type: [Number, String, null],
      default: 0
    }
  },

  data() {
    return {
      isHover: false,
      selectedRating: 0,
      isUserRate: false,
      subTitle: 'Нет оценок',
      rating: 0
    };
  },

  watch: {
    val: {
      immediate: true,
      handler(val) {
        this.rating = Number(val);
      }
    }
  },

  mounted() {
    if (this.avgRating) {
      this.subTitle = `Средняя оценка ${this.avgRating}`;
    } else {
      this.subTitle = 'Нет оценок';
    }
  },

  methods: {
    getIcon(num) {
      const rating = this.isHover ? this.selectedRating : this.rating;
      if (rating - num >= 0) {
        return 'star-active';
      } if (rating - num > -1) {
        return 'star-half';
      }
      return 'star';
    },

    async rateObject(val) {
      if (this.isUserRate) return;

      const api = new Api();
      const res = await api.getUserInfo();
      if (res?.userId > 0) {
        this.isUserRate = true;
        this.subTitle = 'Благодарим за оценку';
        setTimeout(() => {
          this.subTitle = `Ваша оценка ${val}`;
          this.isUserRate = false
        }, 2000);
        this.$emit('change', val);
      } else {
        window.dispatchEvent(
          new CustomEvent('openModal', {
            detail: {
              name: 'LoginModal',
              props: {
                title: 'Пожалуйста, авторизуйтесь',
                text: 'Оценка объектов доступна только авторизованным пользователям'
              }
            }
          })
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.star-container {
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
}
</style>
