import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    isObjectLoading: null,
    isHasObjects: false,
    loadId: null
  },

  mutations: {
    setObjects(state, objects) {
      state.objects = objects;
    },
    setLoadId(state, objects) {
      state.loadId = objects;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
      status && (state.isHasObjects = true);
    }
  },
  getters: {
    getList: () => {
      return state.objects;
    },
    getLoadId: () => {
      return state.objects;
    }
  },
  actions: {
    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList();
      ctx.commit('setObjects', res);
      ctx.commit('setObjectLoading', false);
    },
    async loadId(ctx, id) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getLoadId(id);
      ctx.commit('setLoadId', res);
      ctx.commit('setObjectLoading', false);
      return res;
    }
  },
  modules: {}
});
