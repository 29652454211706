<template>
  <div style="width: 100%;height: 100%;">
    <div
      :class="['culture__map', { 'culture__map--collapsed': isMapCollapsed }]"
      :key="componentKey"
      ref="map"
    >
      <RirMap :garbage="garbage" :center="coordinates || $cityCenter" :zoom="zoom">
        <div v-if="balloons">
          <ymap-marker
            v-if="marker.lat"
            v-for="marker in objects"
            :key="marker.id"
            :coords="[`${marker.lat}`, `${marker.lng}`]"
            :marker-id="marker.id"
            :options="{ hideIconOnBalloonOpen: false, balloonOffset: [65, -10] }"
            :icon="marker.icon || mapIcons[marker.type - 1]"
            cluster-name="main"
            clusterNumbers="clusterNumbers"
            :balloon="{header: {id: marker.id, name: marker.address}}"

          >
            <BalloonCard
              slot="balloon"
              :typeId="marker.type"
              :type="marker.typeName"
              :address="marker.address"
              :item="marker"
            >
            </BalloonCard>
          </ymap-marker>
        </div>
        <div v-else>
          <ymap-marker
            :coords="coordinates || $cityCenter"
            :marker-id="'marker'"
            :options="{
              hideIconOnBalloonOpen: false,
              draggable: false
            }"
            :icon="mapIcons[type - 1]"
            cluster-name="main"
          >
          </ymap-marker>
        </div>
        <ymap-marker
          v-for="transport of transportData.vehicleMarker.anims"
          v-if="transport.rtype === 'garbage' && garbage"
          :marker-id="transport.id"
          :coords="[transport.lat, transport.lon]"
          :options="{ hideIconOnBalloonOpen: false }"
          :icon="{
                 layout: 'default#image',
                 imageSize: [39, 18],
                 imageHref: `data:image/svg+xml;charset=UTF-8,%3csvg width='52' height='24' viewBox='0 0 52 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 8C0 3.58172 3.58172 0 8 0H40C46.6274 0 52 5.37258 52 12V24H8C3.58172 24 0 20.4183 0 16V8Z' fill='%23643302'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 4C10.3431 4 9 5.34315 9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H23C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7C23 5.34315 21.6569 4 20 4H12ZM21 7H11C11 6.44772 11.4477 6 12 6H20C20.5523 6 21 6.44772 21 7Z' fill='white'/%3e%3cpath d='M11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11V16C9 17.3062 9.83481 18.4175 11 18.8293V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19H19C19 19.5523 19.4477 20 20 20C20.5523 20 21 19.5523 21 19V18.8293C22.1652 18.4175 23 17.3062 23 16V11C23 10.4477 22.5523 10 22 10C21.4477 10 21 10.4477 21 11V16C21 16.5523 20.5523 17 20 17H12C11.4477 17 11 16.5523 11 16V11Z' fill='white'/%3e%3cpath d='M18 12C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10H14C13.4477 10 13 10.4477 13 11C13 11.5523 13.4477 12 14 12H18Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 2C34.4772 2 30 6.47715 30 12C30 17.5228 34.4772 22 40 22H50V12C50 6.47715 45.5228 2 40 2ZM40 24H50H52V22V12C52 5.37258 46.6274 0 40 0C33.3726 0 28 5.37258 28 12C28 18.6274 33.3726 24 40 24ZM36 12C36 9.79086 37.7909 8 40 8C42.2091 8 44 9.79086 44 12C44 14.2091 42.2091 16 40 16C37.7909 16 36 14.2091 36 12Z' fill='white'/%3e%3c/svg%3e`
           }"
        >
          <balloon-transport
            slot="balloon"
            :transport="transport"
            :data-id="transport.id"
          />
        </ymap-marker>
        <!-- <div v-if="objects">
           <ymap-marker
             :coords="$cityCenter"
             :marker-id="'marker'"
             :options="{
               hideIconOnBalloonOpen: false,
               draggable: false
             }"
             :icon="$markerIconToo"
           >
           </ymap-marker>
         </div>-->
      </RirMap>
      <!-- <loading-content v-else /> -->
      <button class="culture__map-collapse-btn feta" @click="isMapCollapsed = !isMapCollapsed">
        <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
      </button>
    </div>
  </div>
</template>

<script>
import RirMap from './RirMap';
import BalloonCard from './BalloonCard';
import MapSearch from './MapSearch';
import { loadGarbageIcons } from '../helpers/utils';
import BalloonTransport from '../components/BalloonTransport';

export default {
  name: 'MapCulture',
  props: {
    objects: {
      type: Array
    },
    searchable: {
      type: Boolean,
      default: false
    },
    garbage: {
      type: Boolean,
      default: false
    },
    balloons: {
      type: Boolean,
      default: false
    },
    componentKey: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    coordinates: {
      type: Array,
      default: () => $cityCenter
    },
    zoom: {
      type: Number,
      default: 12
    }
  },
  components: {
    RirMap,
    BalloonCard,
    MapSearch,
    BalloonTransport
  },

  data() {
    return {
      transportData: {
        vehicleMarker: {
          anims: []
        }
      },
      socket: null,
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      mapIcons: [this.$markerIcon, this.$tko, this.$wasteBin, this.$wasteCam, this.$separate]
    };
  },
  async mounted() {
    if(!this.socket) {
      const host = window.location.host === 'localhost:4000' ? 'test.rosatom.city' : window.location.host;
      this.socket = await new WebSocket(`wss://${host}/transport/rest/ws/online?type=clearing`);// connectionCount=0&

      this.socket.onmessage = e => {
        this.transportData = JSON.parse(e.data);
      };

      this.socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        } else {
          // например, сервер убил процесс или сеть недоступна
          // обычно в этом случае event.code 1006
          console.log('[close] Соединение прервано');
        }
      };

      this.socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
      };
    }
  },
  methods: {
    clusterBalloon(marker) {
      return `<a href="${
        this.$router.resolve({
          name: 'info',
          params: { id: marker.id }
        }).href
      }" style="color: #0e1420;">
        <div class="rir-map-balloon rir-map-balloon__claster">
             <div class="rir-map-balloon__info">
                <p class="roquefort">${marker.typeName}</p>
                ${
                  !!marker.address
                    ? `<p class="mt-1 сaprino rir-map-balloon__address">
                      ${marker.address}
                    </p>`
                    : ''
                }
                ${
                  loadGarbageIcons(marker.extraData, marker.type).flag
                    ? `<div class="obj-card__counters flex obj-card__address mozzarella style-marin-house mt-3">
                       ${loadGarbageIcons(marker.extraData, marker.type)
                         .visibleIcons.map(
                           garbage =>
                             ` <div class="mr-2 img other-icon" data-title='${garbage.title}'>
                                    <img src='${garbage.icon}'>
                                </div>`
                         )
                         .join('')}
                    </div>`
                    : ''
                }

                <div class="mt-3">
                  <div class="flex align-cenetr">
                    <div class="flex mr-5">
                        <i data-v-01742b42="" class="rir-icon mr-2">
                          <svg width="16px" height="16px" viewBox="0 0 17 17" fill="var(--rir-rocky)" xmlns="http://www.w3.org/2000/svg" class="svg-class">
                            <path d="M2.80518 11.0013C1.14833 8.13158 2.13158 4.46204 5.00134 2.80518C7.87109 1.14833 11.5406 2.13158 13.1975 5.00134C13.8024 6.04907 14.0549 7.20045 13.9935 8.32246C13.9634 8.87392 14.386 9.34541 14.9375 9.37557C15.4889 9.40572 15.9604 8.98312 15.9906 8.43166C16.0725 6.93384 15.7344 5.39534 14.9295 4.00134C12.7204 0.174995 7.82768 -1.13601 4.00134 1.07313C0.174995 3.28227 -1.13601 8.17499 1.07313 12.0013C3.28227 15.8277 8.17499 17.1387 12.0013 14.9295C13.0623 14.317 13.9319 13.4961 14.5858 12.5478C14.8994 12.0932 14.785 11.4704 14.3303 11.1569C13.8756 10.8434 13.2529 10.9578 12.9394 11.4124C12.4504 12.1215 11.7999 12.7365 11.0013 13.1975C8.13158 14.8543 4.46204 13.8711 2.80518 11.0013Z" fill="var(--rir-rocky)"></path> <path d="M9.00087 4.00136C9.00087 3.44907 8.55315 3.00136 8.00087 3.00136C7.44858 3.00136 7.00087 3.44907 7.00087 4.00136V7.46617C7.00087 8.13488 7.33507 8.75934 7.89147 9.13027L10.4462 10.8334C10.9057 11.1398 11.5266 11.0156 11.8329 10.5561C12.1393 10.0965 12.0151 9.47566 11.5556 9.1693L9.00087 7.46617V4.00136Z" fill="var(--rir-rocky)"></path>
                          </svg>
                        </i>
                      ${
                        marker.extraData != null
                          ? `<span class="briscola clock">
                            ${
                              marker.extraData.another
                                ? marker.extraData.another
                                : marker.extraData.days + ' ' + marker.schedule
                            }
                          </span>`
                          : `<span class="briscola clock">
                            -
                          </span>`
                      }
                    </div>
                  </div>
                </div>
             </div>
        </div>

        </a>`;
    }
  }
};
</script>

<style scoped lang="scss">
.culture {
  &__map {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}
.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153e;
}

.rir-map-balloon {
  width: 270px;
  display: table;

  &__img {
    width: 100%;
    height: 122px;
  }

  &__info {
    padding: 16px;

    & > [class^='mt_'] {
      margin-top: 0 !important;
    }
  }

  &__address {
    position: static;
    height: 12px;
    left: 0px;
    right: 91px;
    top: 52px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #04153e;
    opacity: 0.72;
  }
}

.roquefort {
  color: #0e1420;
}

.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153e;
}
@media screen and (max-width: 1023px) {
  .culture {
    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map {
      height: 100vw;
      max-height: 447px;
      margin-bottom: 32px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dashed #214eb052;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .culture {
    &__map--collapsed {
      height: 144px;
    }
  }
}
</style>
