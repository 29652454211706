import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import rir from './plugins/RirLib';
import YmapPlugin from 'vue-yandex-maps';
//sttngs
const mapSettings = {
  apiKey: '09e7101d-6593-4fab-a0a7-0b170fec06ff',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};
Vue.use(YmapPlugin, mapSettings);
Vue.config.productionTip = false;

Vue.prototype.$markerIconToo = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON_ONE
};
window._axios.interceptors.response.use(
  response => {
    // For status code with 2xx
    if (response?.error) {
      rir.framework.notification.send({
        title: response?.error || 'Ошибка!',
        seconds: 5,
        hiddenClose: false,
        iconOptions: {
          icon: 'warning',
          fill: 'fargo'
        }
      });
    }
    return response;
  },
  error => {
    // Status code outside the range of 2xx
    // handle error cases
    console.error('error', error);
    return Promise.reject(error);
  }
);
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter =
  window?.__CONFIG__?.cityCenter?.latLng ||
  process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

Vue.prototype.$markerIcon = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON
};
Vue.prototype.$clusterIcon = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_CLUSTER_ICON
};
Vue.prototype.$tko = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_TKO_ICON
};
Vue.prototype.$wasteBin = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_BIN_ICON
};
Vue.prototype.$wasteCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_ICON
};

Vue.prototype.$separate = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_SEPARATE
};

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
