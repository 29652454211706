<template>
  <r-input
    v-model="search"
    class="mb-6 culture__input-search"
    label="Поиск по адресу"
    after-icon="search"
    type="map"
  />
</template>

<script>
export default {
  name: 'MapSearch',
  props: ['modelSearch'],
  emits: ['update:modelSearch'],
  data() {
    return {
      timeout: null
    };
  },
  computed: {
    search: {
      get() {
        return this.modelSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit('update:modelSearch', val);
        }, 420);
      }
    }
  }
};
</script>

<style scoped></style>
